import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SteelIron from "../assets/images/Industries/SteelIron.jpg"
import Glass from "../assets/images/Industries/Glass.jpg"
import Automotive from "../assets/images/Industries/Automotive.jpg"
import Pharmaceutical from "../assets/images/Industries/Pharmaceutical.jpg"
import Textile from "../assets/images/Industries/Textile.jpg"
import Chemical from "../assets/images/Industries/Chemical.jpg"
import Energy from "../assets/images/Industries/Energy.jpg"
import FoodBeverage from "../assets/images/Industries/FoodBeverage.png"
import "../styles/industry.scss"

class Industries extends Component {
  state = {
    industriesData: [],
  }
  industries = () => {
    const { industriesData } = this.state
    const industries = industriesData.map(industry => {
      return (
        <div className="industry industry-row" key={industry.name}>
          <img
            className="industry-image"
            // style={{ display: "flex", margin: "auto" }}
            // width="250px"
            src={industry.image}
            alt="name"
          />
          <p className="industry-name">{industry.name}</p>
        </div>
      )
    })
    return industries
  }

  componentDidMount() {
    const industriesData = [
      {
        image: SteelIron,
        name: "Steel Iron",
      },
      {
        image: Glass,
        name: "Glass",
      },
      {
        image: Automotive,
        name: "Automotive",
      },
      {
        image: Pharmaceutical,
        name: "Pharmaceutical",
      },
      {
        image: Textile,
        name: "Textile",
      },
      {
        image: Chemical,
        name: "Chemical",
      },
      {
        image: Energy,
        name: "Energy",
      },
      {
        image: FoodBeverage,
        name: "FoodBeverage",
      },
    ]
    this.setState({ industriesData })
  }
  render() {
    const industryData = this.industries()
    return (
      <Layout>
        <SEO title="Industries" />
        <div className="">
          {/* <p>Industries</p> */}
          {industryData}
        </div>
      </Layout>
    )
  }
}

export default Industries
